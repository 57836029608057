<template>
  <div class="sidebar-detached sidebar-left " style="z-index: 100;">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
      
        <b-card>
     
          <div >
                    
             
    
      <FiltroSidebarBooking
         @sendQuote="sendTransfer"
      />

          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FiltroSidebarBooking from '@/modules/shop/components/transfers/FiltroSidebarBooking'
import * as moment from 'moment'  
import {
  adultOptions,
  childrenOptions,
} from "@/data/data/";
//seeds data
import { toJson } from '@/helpers/helpers'
import flatPickr from 'vue-flatpickr-component'
export default {
  async created() {

  },
  components:{
    flatPickr,
    FiltroSidebarBooking,
    AppCollapse,AppCollapseItem,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dateRange:moment(new Date()).format("YYYY-MM-DD"),
      adults:1,
      children:0,
      adultOptions,
      childrenOptions,
      isload:false

    };
  }, 
  watch: {
    currency: {
      deep: true,
      async handler() {
        await this.sendTransfer()
      },
    },
  },
  computed:{
    ...mapState('appConfig',['layout']),
    ...mapState('start',['categories', 'isLoadingCategories']),
    ...mapState('products',['isloadingProducts','selectedCategory', '']),
    ...mapState('start',['queryTransferParams']),
    ...mapState('shop',['currency']),
    
    colorFilter(){
      return this.layout.skin === 'dark' ? 'title-filter-dark' : 'title-filter-light'
    },

  },
  methods:{
    ...mapMutations('start',['setParamsRates']),
    ...mapActions('products',['fetchTransfersRate']),
    ...mapMutations('products',['setTransfers','setSelectedExperienceDate']),
    

    async sendTransfer(){
      let transfers
      transfers = await this.fetchTransfersRate(this.queryTransferParams)
      this.setTransfers(transfers)
      this.$emit('set-current-page')
      return transfers
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.title-filter-dark {
  color: white;
  font-weight: bold;
}

.title-filter-light {
  color: black;
  font-weight: bold;
}

</style>
