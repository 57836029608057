<template>
  <b-row>
    <b-col md="3" class="mb-1">
      <div class="result-toggler">
        <feather-icon
          icon="MenuIcon"
          class="d-block d-lg-none"
          size="21"
          @click="mQShowSidebarLeft"
        />
      <b-badge variant="warning" v-if="!isloadingProducts"> {{ filteredTransfers.length }} traslados encontrados </b-badge>      
      <b-spinner  variant="success" v-if="isloadingProducts"/>

    </div>
    </b-col>
    <b-col md="6" class="mb-1">
      <b-input-group class="input-group-merge border">
        <b-form-input
          v-model="filter.query"
          placeholder="Buscar Traslado"
          class="search-product"
          @input="setSearch"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-append>
      </b-input-group>
    </b-col>    
    <b-col md="3" class="mb-1">
      <div class="d-flex justify-content-end">
        <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="sortBy.text"
              right
              variant="primary"
        >                          
          <b-dropdown-item
            v-for="sortOption in sortByOptions"
            :key="sortOption.value"
            @click="sortByOption(sortOption)"
          >
            {{ sortOption.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-col>
  </b-row>    
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    mqShallShowLeftSidebar:{
       type: Boolean,
      required: true,      
    },
    sortBy: {
      type: Object,
      required: true,
    },
    sortByOptions:{
      type: Array,
      required: true,
    },    
    itemViewOptions:{
      type: Array,
      required: true,
    }   
  }, 
  directives: {
    Ripple,
  },  
  data() {
    return {
      itemView:'grid-view',      
    }
  },
  computed:{        
    ...mapState('products',['filter','isloadingProducts']),    
    ...mapGetters('products',['filteredTransfers']), 
  },
  methods:{
    chageView(viewType){      
      this.$emit("change-view", viewType)   
    },
    sortByOption(option){      
      this.$emit("sort-by-option", option)   
    },
    mQShowSidebarLeft(){
      this.$emit("mq-show-sidebar-left", true)   
    },
    setSearch(){
      this.$emit("set-current-page", 1)   
    }   
  }
}
</script>

