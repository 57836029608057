<template>
  <div class="img-exp">
    
    <div >  
      <b-link
        
      >    
        <img :src="imgUrlTransfer + product.coverImage" :alt="`${product.name}-${product.id}`" @error="imgAlternativo" height="200" width="100%" style="cursor: pointer">
      </b-link>


    </div>
  </div>
</template>

<script>
 
export default {
  props:{
    product: {
      type: Object,
      required: true
    },
    payloadParamsRates: {
      type: Object,
      default: null
    },
  }, 
  data(){
    return {
      imgUrlTransfer: process.env.VUE_APP_IMGETRF_SRC_API,
      imgUrlExperience: process.env.VUE_APP_IMGEXP_SRC_API,
      imdDefault: require('@/assets/images/default.jpg')
      // imdDefault: 'https://picsum.photos/seed/picsum/200/300'      
    }
  },
  methods:{
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    showProduct(product){      
      this.$router.push({ name: 'sales-product-details', params: { slug: 'products-'+ product.id } })
    },
  }
}
</script>


<style>
.card-img-top {    
    height: 10rem;
    object-fit: cover;
}
.img-exp{
  height: 200px;
}
</style>