<template>
  <div class="sidebar__item">
 
                         
    <div id="myContent" class="sidebar__item--content" v-if="!isReadOnlyForm" >  
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">Filtro

        </label>
        <b-form-radio-group
      v-model="queryTransferParams.selectedOption"
      :options="transportOptions"
      name="transport"
      class="mb-3"
    ></b-form-radio-group>
      </div>

      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> Fecha ida</label>
        <div class="sidebar__item-form--input">
          <flat-pickr              
            class="form-control mb-1"
            placeholder="Seleccione fecha"              
            v-model="queryTransferParams.operationDateArrival"
            @input="setDateQueryParams"   
            :config="setDateInConfigGlobal" 
          />
        </div>
      </div>

      <div class="sidebar__item-form" v-if="queryTransferParams.selectedOption=='2'">
        <label class="sidebar__item-form--label"> Fecha vuelta</label>
        <div class="sidebar__item-form--input">
          <flat-pickr              
            class="form-control mb-1"
            placeholder="Seleccione fecha"               
            v-model="queryTransferParams.operationDateDeparture"
            @input="setDateQueryParams"   
            :config=" setDateOutConfigGlobal" 
          />
        </div>
      </div>
      <div class="sidebar__item-form">
            <ValidationProvider rules="required" name="origen">
              <b-form-group slot-scope="{ valid, errors }" label="Origen">
  
                <v-select
                 :state="errors[0] ? false : (valid ? true : null)"
          :options="places"
          :reduce="place => place.displayES"
          label="name"
          placeholder="Seleccione Origen"
          @input="onInputOrigin"
          v-model="queryTransferParams.originPlaceName"
       
        ></v-select>
               
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
              
            </ValidationProvider>

            
          </div>
        
          <div class="sidebar__item-form">
            <ValidationProvider name="Destino" rules="required">
      <b-form-group slot-scope="{ valid, errors }" label="Destino">
        <v-select
          :options="places"
          :state="errors[0] ? false : (valid ? true : null)"
          :reduce="place => place.displayES"
          label="name"
          placeholder="Seleccione Destino"
          @input="onInputDestination"
          v-model="queryTransferParams.destinationPlaceName"
         
        ></v-select>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
        </div>
      <div class="sidebar__item-form">
 
        
   
      </div>    
       <validation-provider name="Adultos" rules="required">
                <b-form-group slot-scope="{ errors }" label="No. Personas*">
                  <b-form-select
                    v-model="queryTransferParams.pax"
                    :state="errors.length > 0 ? false : null"
                   
                  
                  >
                    <!-- @input="setDataForm"
									 -->
                    <option
                      v-for="(adulto, index) in adultOptions"
                      :key="index"
                      :value="adulto.value"
                    >
                      {{ adulto.title }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
      <div class="sidebar__item-form">
        <div class="btn-search">
          <button class="button button--flex" @click="searchData" :disabled="isloadingProducts">        
                       
           Buscar
          </button>
        </div>                                                             
      </div>
    </div>

    <div id="myContent" class="sidebar__item--content " v-else>    
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">Filtro
          <b-button variant="primary"  class="btn-blue" size="sm">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 9.5L0.146447 9.14645L0 9.29289V9.5H0.5ZM9.5 0.5L9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L9.5 0.5ZM14.5 5.5L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L14.5 5.5ZM5.5 14.5V15H5.70711L5.85355 14.8536L5.5 14.5ZM0.5 14.5H0C0 14.7761 0.223858 15 0.5 15L0.5 14.5ZM0.853553 9.85355L9.85355 0.853553L9.14645 0.146447L0.146447 9.14645L0.853553 9.85355ZM9.14645 0.853553L14.1464 5.85355L14.8536 5.14645L9.85355 0.146447L9.14645 0.853553ZM14.1464 5.14645L5.14645 14.1464L5.85355 14.8536L14.8536 5.85355L14.1464 5.14645ZM5.5 14H0.5V15H5.5V14ZM1 14.5V9.5H0V14.5H1ZM6.14645 3.85355L11.1464 8.85355L11.8536 8.14645L6.85355 3.14645L6.14645 3.85355ZM8 15H15V14H8V15Z" fill="#ffffff"/>
            </svg> 
          </b-button>
        </label>
   

      </div>   
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> Fecha </label>
        <div class="sidebar__item-form--input">
          {{ queryTransferParams.date }}
          <!-- desde el mixin market -->
        </div>
      </div>
      <div class="sidebar__item-form">
        <div class="item__total-guests">        
   
          <span>No. Personas</span>                                                
          <button class="item__btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
        </div>  
        <div class="guests-pax">                
          <span  class="pax-type"> pax </span>                                          
          <span> {{ queryTransferParams.pax }} </span>                      
        </div>      
          
      </div>    
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { market } from '@/modules/shop/mixins/market'
  import { adultOptions } from "@/data/data";
  import flatPickr from 'vue-flatpickr-component'
  import vSelect from 'vue-select'
  import * as moment from 'moment'  

  export default {   
     mixins: [market],   
    components: {             
      vSelect,
      flatPickr              
    }, 

    mounted(){
      this.queryTransferParams.operationDateArrival = moment(new Date()).add(1, 'days').format("YYYY-MM-DD");
      this.queryTransferParams.operationDateDeparture = moment(new Date()).add(0, 'days').format("YYYY-MM-DD");

    },
    watch: {
    'queryTransferParams.operationDateArrival'(newDate) {
        if (!newDate) {
            this.queryTransferParams.operationDateDeparture = '';
        } else {
            const selectedDate = moment(newDate, "YYYY-MM-DD");
            const nextDay = selectedDate.add(0, 'days').format("YYYY-MM-DD");
            this.queryTransferParams.operationDateDeparture = nextDay;
        }
    }
  },
    data(){
      return{
        isReadOnlyForm: false,
        transportOptions: [
        { text: 'Solo ida', value: '1' },
        { text: 'Redondo', value: '2' }
      ],
      adultOptions 
        
      
   
      }
    },
    computed: {
    ...mapState('products',['isloadingProducts','selectedCategory','places']),
    ...mapState('start',['queryTransferParams']),       
      totalGuest()  {
        return parseFloat(this.queryTransferParams?.pax) 
      },

      setDateInConfigGlobal() {
      return {
        minDate: moment().add(1, 'days').format("YYYY-MM-DD")
      }
     },
    setDateOutConfigGlobal(){
            const selectedDate = new Date(this.queryTransferParams.operationDateArrival);
            const nextDay = new Date(selectedDate);
            nextDay.setDate(selectedDate.getDate() );
           let returnMinDate = nextDay.toISOString().split('T')[0]; 
           
      return {
           
        minDate: returnMinDate
      }
    },

    },
    methods:{
      
    ...mapMutations('start',['setSubCategoryQueryParam','setNumberChildren','setNumberPax','setDateInQueryParams']),     
    onInputDestination(event) {
      const placeName = event;
      const selectedPlace = this.places.find(place => place.displayES === placeName);
      if (selectedPlace) {
        this.queryTransferParams.destinationPlaceId = selectedPlace.placeId;
        this.selectedPlaceName = selectedPlace.displayES;
      } else {
        this.queryTransferParams.destinationPlaceId = null;
        this.selectedPlaceName = placeName;
      }
    },

    
    onInputOrigin(event) {
      const placeName = event;
      const selectedPlace = this.places.find(place => place.displayES === placeName);
      if (selectedPlace) {
        this.queryTransferParams.originPlaceId = selectedPlace.placeId;
        this.selectedPlaceName = selectedPlace.displayES;
      } else {
        this.queryTransferParams.originPlaceId = null;
        this.selectedPlaceName = placeName;
      }
    },


      setDateQueryParams(date){
        this.setDateInQueryParams(date)  
        
      },

      setPaxAdults(operator){
        const adults = operator === '-' ? parseFloat( this.queryTransferParams.pax ) - 1 : parseFloat( this.queryTransferParams.pax) + 1            
        this.setNumberPax(adults)

      },

            async searchData(){
     
           this.$emit('sendQuote')

      },
  },        
  }
</script>

<style lang="scss" scoped>

  @import "@/assets/scss/filter-search.scss";  

</style>
<style >

.vs--searchable ul{
  width: auto;

}


</style>
